import {
  ThemeProvider,
  Hero,
  SectionIntro,
  Grid,
  Pillar,
  River,
  Image,
  Text,
  Testimonial,
  CTABanner,
  Button,
  FAQ,
} from '@primer/react-brand'
import {NorthStarIcon, PlayIcon, StackIcon, ZapIcon} from '@primer/octicons-react'
import {Spacer} from './components/Spacer'

const extURLs = {
  try: 'https://github.com/search?type=code&auto_enroll=true',
  video: 'https://www.youtube.com/watch?v=ujVY8xqkflQ',
}

export function CodeSearchIndex() {
  return (
    <ThemeProvider colorMode="light" className="fp-hasFontSmoothing">
      <Spacer size="48px" size1012="96px" />

      {/* Section Hero */}

      <section id="hero">
        <div className="fp-Section-container">
          <Hero data-hpc align="center" className="fp-Hero">
            <Hero.Label color="green">Code Search</Hero.Label>
            <Hero.Heading className="fp-Hero-heading" size="2">
              Exactly what you’re <br className="fp-breakWhenWide" /> looking for
            </Hero.Heading>
            <Hero.Description className="fp-Hero-description" size="300">
              With GitHub Packages, you can safely publish and consume packages within your organization or with the
              entire world.
            </Hero.Description>
            <Hero.PrimaryAction href={extURLs.try}>Try it now</Hero.PrimaryAction>
            <Hero.SecondaryAction href={extURLs.video} trailingVisual={<PlayIcon />}>
              Watch video
            </Hero.SecondaryAction>
            <Hero.Image
              src="/images/modules/site/code-search/fp24/hero.webp"
              alt="Screenshot of the GitHub code review interface displaying a repository's file structure and code editor. The left panel shows a file explorer with folders such as .github, .reuse, LICENSES, and library, with the wtf8.rs file currently open in the main editor. The code in the editor is written in Rust, defining a CodePoint struct and its related implementation. A search box at the bottom left shows search results for 'CodePoint' within the repository, listing various matches such as class CodePoint and implementation CodePoint. On the right side, a detailed symbol reference panel shows the definition of CodePoint and lists 24 references found in the code. The background features a gradient from green to teal, highlighting the focused and interactive nature of the code review process."
            />
          </Hero>
        </div>
      </section>

      {/* Cards */}

      <section id="cards">
        <div className="fp-Section-container">
          <Spacer size="56px" size1012="112px" />

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">
              Search, navigate, and understand your <br className="fp-breakWhenWide" /> team’s code—and billions of
              lines of <br className="fp-breakWhenWide" /> public code.
            </SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="40px" size1012="80px" />

          <Grid className="lp-Grid">
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 4}}>
              <Pillar>
                <Pillar.Icon color="green" icon={<NorthStarIcon />} />
                <Pillar.Heading>Fast, relevant results</Pillar.Heading>
                <Pillar.Description>
                  Code search understands your code—and brings you relevant results with incredible speed.
                </Pillar.Description>
              </Pillar>
            </Grid.Column>
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 4}}>
              <Pillar>
                <Pillar.Icon color="green" icon={<ZapIcon />} />
                <Pillar.Heading>A power userʼs dream</Pillar.Heading>
                <Pillar.Description>
                  Search using regular expressions, boolean operations, keyboard shortcuts, and more.
                </Pillar.Description>
              </Pillar>
            </Grid.Column>
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 4}}>
              <Pillar>
                <Pillar.Icon color="green" icon={<StackIcon />} />
                <Pillar.Heading>More than just search</Pillar.Heading>
                <Pillar.Description>
                  Dig deeper with the all-new code view—tightly integrating browsing and code navigation.
                </Pillar.Description>
              </Pillar>
            </Grid.Column>
          </Grid>
        </div>
      </section>

      {/* Features */}

      <section id="features">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <SectionIntro className="fp-SectionIntro" align="center">
            <SectionIntro.Heading size="3">Way more than grep.</SectionIntro.Heading>
            <SectionIntro.Description>
              GitHub code search can search across multiple repositories and is always up to date. It understands your
              code, and puts the most relevant results first.
            </SectionIntro.Description>
          </SectionIntro>

          <Spacer size="64px" size1012="96px" />

          <River className="fp-River" align="end">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/code-search/fp24/features-river-1.webp"
                alt="Screenshot showing the GitHub code search interface, with a search query for 'CodePoint' within the rust-lang/rust repository. The search results include various symbols and files related to 'CodePoint,' such as 'class CodePoint' in the library/wtf8.rs file, 'implementation CodePoint' in another file, and specific functions and fields like from_u32_unchecked and is_known_utf8. Each result has a 'Jump to' link on the right, allowing users to quickly navigate to the corresponding code. The background features a gradient from teal to green."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold">
                <strong>Suggestions, completions, and more.</strong> Use the new search input to find symbols and
                files—and jump right to them.
              </Text>
            </River.Content>
          </River>

          <River className="fp-River">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/code-search/fp24/features-river-2.webp"
                alt="Screenshot of advanced GitHub search queries, including searches within the rust-lang organization, the tensorflow/tensorflow repository, and for 'validation' in Ruby or Python code. The background features a teal-to-green gradient."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold">
                <strong>Powerful search syntax.</strong> Know exactly what you’re looking for? Express it with our
                powerful search operators.
              </Text>
            </River.Content>
          </River>

          <Spacer size="64px" size1012="128px" />

          <SectionIntro className="fp-SectionIntro" align="center">
            <SectionIntro.Heading size="3">Meet the all-new code view.</SectionIntro.Heading>
            <SectionIntro.Description>
              Dig deeper into complex codebases with tightly integrated search, code navigation and browsing.
            </SectionIntro.Description>
          </SectionIntro>

          <Spacer size="64px" size1012="96px" />

          <River className="fp-River" align="end">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/code-search/fp24/features-river-3.webp"
                alt="Screenshot of GitHub's code navigation panel showing details for the symbol CodePoint. It highlights the definition of CodePoint as a struct in the code and lists 24 references to it within the same file. The interface is dark-themed with a gradient background transitioning from green to blue."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold">
                <strong>Code navigation.</strong> Instantly jump to definitions in over 10 languages. No setup required.
              </Text>
            </River.Content>
          </River>

          <River className="fp-River">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/code-search/fp24/features-river-4.webp"
                alt="Screenshot of the file explorer in GitHub's code interface, showing a directory structure. The main branch (main) is selected at the top. Folders like .github, .reuse, LICENSES, and library are visible, with the library folder expanded to reveal files such as backtrace.rs, condvar.rs, fs.rs, and wtf8.rs, with wtf8.rs currently selected. The background features a gradient from green to teal."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold">
                <strong>File browser.</strong> Keep all your code in context and instantly switch files with the new
                file tree pane.
              </Text>
            </River.Content>
          </River>

          <Spacer size="64px" size1012="128px" />
        </div>
      </section>

      {/* CTA */}

      <section className="fp-Section--isRaised">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">What developers are saying</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="48px" size1012="96px" />

          <Grid className="lp-Grid lp-Grid--isRaised">
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 6}}>
              <Testimonial quoteMarkColor="green">
                <Testimonial.Quote>
                  Code search makes it effortless to quickly find what Iʼm looking for in my code, or across all of
                  GitHub
                </Testimonial.Quote>
                <Testimonial.Name position="Software Engineer">Keith Smiley</Testimonial.Name>
                <Testimonial.Avatar
                  src="/images/modules/site/code-search/fp24/testimonials-avatar-keith.jpg"
                  alt="Circular avatar from Keith Smiley's GitHub profile"
                />
              </Testimonial>
            </Grid.Column>
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 6}}>
              <Testimonial quoteMarkColor="green">
                <Testimonial.Quote>
                  Code search turns what wouldʼve been a ~10 minute grep search into a 2 second UI search
                </Testimonial.Quote>
                <Testimonial.Name position="Platform Engineer">Marco Montagna</Testimonial.Name>
                <Testimonial.Avatar
                  src="/images/modules/site/code-search/fp24/testimonials-avatar-marco.jpg"
                  alt="Circular avatar from Marco Montagna's GitHub profile"
                />
              </Testimonial>
            </Grid.Column>
          </Grid>

          <Spacer size="48px" size1012="96px" />

          <ThemeProvider colorMode="dark">
            <CTABanner className="lp-CTABanner" align="center" hasShadow={false}>
              <CTABanner.Heading size="2">Find more, search less</CTABanner.Heading>
              <CTABanner.Description className="lp-CTABannerDescription">
                TODO: Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sapien sit ullamcorper id. Aliquam
                luctus sed turpis felis nam pulvinar risus elementum.
              </CTABanner.Description>
              <CTABanner.ButtonGroup buttonsAs="a">
                <Button href={extURLs.try}>Try it now</Button>
                <Button href={extURLs.video}>Watch video</Button>
              </CTABanner.ButtonGroup>
            </CTABanner>
          </ThemeProvider>

          <Spacer size="48px" size1012="96px" />

          <FAQ id="faq" className="lp-FAQ">
            <FAQ.Heading>Frequently asked questions</FAQ.Heading>
            <FAQ.Item>
              <FAQ.Question>Do I need to set up my repositories to support code navigation?</FAQ.Question>
              <FAQ.Answer>
                <p>No, code navigation works out of the box, with zero configuration required.</p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>Who can search my code?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  Public code is searchable by anyone, but private code can only be searched by users who have access to
                  it.
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>How much does the new code search and code view cost?</FAQ.Question>
              <FAQ.Answer>
                <p>The new code search and code view are free for users of GitHub.com.</p>
              </FAQ.Answer>
            </FAQ.Item>
          </FAQ>

          <Spacer size="64px" size1012="128px" />
        </div>
      </section>
    </ThemeProvider>
  )
}

try{ CodeSearchIndex.displayName ||= 'CodeSearchIndex' } catch {}