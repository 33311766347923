import {
  ThemeProvider,
  Hero,
  Image,
  SectionIntro,
  Grid,
  Card,
  Box,
  AnchorNav,
  Bento,
  Link,
  RiverBreakout,
  Text,
  Timeline,
  CTABanner,
  Button,
} from '@primer/react-brand'
import {Spacer} from './components/Spacer'
import {
  BookIcon,
  ChecklistIcon,
  CodeIcon,
  DeviceDesktopIcon,
  DeviceMobileIcon,
  HeartIcon,
  MortarBoardIcon,
  PackageIcon,
  TerminalIcon,
} from '@primer/octicons-react'

// Converted from `config/site/features.yml`
const featuresData = [
  {
    id: 'collaborative_coding',
    items: [
      {
        title: 'Codespaces',
        description:
          'Spin up fully configured dev environments in the cloud with the full power of your favorite editor.',
        button_link: '/features/codespaces',
      },
      {
        title: 'GitHub Copilot',
        description:
          'With GitHub Copilot, get suggestions for whole lines or entire functions right inside your editor.',
        button_link: '/features/copilot',
      },
      {
        title: 'Pull requests',
        description:
          "Allow contributors to easily notify you of changes they've pushed to a repository – with access limited to the contributors you specify. Easily merge changes you accept.",
        button_link:
          'https://docs.github.com/pull-requests/collaborating-with-pull-requests/proposing-changes-to-your-work-with-pull-requests/about-pull-requests',
      },
      {
        title: 'Discussions',
        description:
          'Dedicated space for your community to come together, ask and answer questions, and have open-ended conversations.',
        button_link: 'https://docs.github.com/discussions',
      },
      {
        title: 'Code search & code view',
        description:
          'Our powerful new code search and code view enable developers to rapidly search, navigate, and understand code right from GitHub.com.',
        button_link: '/features/code-search',
      },
      {
        title: 'Notifications',
        description:
          "Get updates on the GitHub activity you've subscribed to. Use the notifications inbox to customize, triage, and manage your updates.",
        button_link: 'https://docs.github.com/account-and-profile/managing-subscriptions-and-notifications-on-github',
      },
      {
        title: 'Code review',
        description:
          'Review new code, see visual code changes, and confidently merge code changes with automated status checks.',
        button_link:
          'https://docs.github.com/pull-requests/collaborating-with-pull-requests/reviewing-changes-in-pull-requests',
      },
      {
        title: 'Code review assignments',
        description:
          'Assign code reviews to make it clear which team members should submit their review for a pull request.',
        button_link:
          'https://docs.github.com/organizations/organizing-members-into-teams/managing-code-review-settings-for-your-team',
      },
      {
        title: 'Code owners',
        description:
          'Automatically request reviews–or require approval—by selected contributors when changes are made to sections of code that they own.',
        button_link:
          'https://docs.github.com/repositories/managing-your-repositorys-settings-and-features/customizing-your-repository/about-code-owners',
      },
      {
        title: 'Draft pull requests',
        description:
          'Use a pull request as a way to discuss and collaborate, without submitting to formal review or risking an unwanted merge.',
        button_link:
          'https://docs.github.com/pull-requests/collaborating-with-pull-requests/proposing-changes-to-your-work-with-pull-requests/about-pull-requests#draft-pull-requests',
      },
      {
        title: 'Protected branches',
        description:
          'Enforce restrictions on how code branches are merged, including requiring reviews, or allowing only specific contributors to work on a particular branch.',
        button_link:
          'https://docs.github.com/repositories/configuring-branches-and-merges-in-your-repository/defining-the-mergeability-of-pull-requests/about-protected-branches',
      },
      {
        title: 'Team reviewers',
        description:
          "Request a team on GitHub to review your pull request. Members of the team will get a notification indicating that you've asked for their review.",
        button_link:
          'https://docs.github.com/organizations/organizing-members-into-teams/managing-code-review-settings-for-your-team',
      },
      {
        title: 'Multiple assignees',
        description:
          "Assign up to 10 people to work on a given issue or pull request, letting you more easily track who's working on what.",
        button_link:
          'https://docs.github.com/issues/tracking-your-work-with-issues/assigning-issues-and-pull-requests-to-other-github-users',
      },
      {
        title: 'Multiple reviewers',
        description:
          "Request review from multiple contributors. Requested reviewers will be notified that you've asked for their review.",
        button_link:
          'https://docs.github.com/pull-requests/collaborating-with-pull-requests/proposing-changes-to-your-work-with-pull-requests/requesting-a-pull-request-review',
      },
      {
        title: 'Multi-line comments',
        description:
          'Clarify code reviews by referencing or commenting on multiple lines at once in a pull request diff view.',
        button_link:
          'https://docs.github.com/pull-requests/collaborating-with-pull-requests/reviewing-changes-in-pull-requests/commenting-on-a-pull-request',
      },
      {
        title: 'Public repositories',
        description:
          'Work with any GitHub member on code in a public repository you control. Make changes, open a pull request, create an issue, and more.',
        button_link: 'https://docs.github.com/repositories/creating-and-managing-repositories/about-repositories',
      },
      {
        title: 'Dark mode',
        description:
          'Choose how you experience GitHub with theme settings. Swap to dark theme or default to your system preferences.',
        button_link:
          'https://docs.github.com/account-and-profile/setting-up-and-managing-your-personal-account-on-github/managing-personal-account-settings/managing-your-theme-settings',
      },
    ],
  },
  {
    id: 'automation',
    items: [
      {
        title: 'Actions',
        description:
          'Automate all your software development workflows. Write tasks and combine them to build, test, and deploy faster from GitHub.',
        button_link: 'https://docs.github.com/actions',
      },
      {
        title: 'Packages',
        description:
          'Host your own software packages or use them as dependencies in other projects. Both private and public hosting are available.',
        button_link: 'https://docs.github.com/packages',
      },
      {
        title: 'APIs',
        description:
          'Create calls to get all the data and events you need within GitHub, and automatically kick off and advance your software workflows.',
        button_link: 'https://docs.github.com/get-started/exploring-integrations/about-building-integrations',
      },
      {
        title: 'GitHub Pages',
        description:
          'Create and publish websites about yourself, your organization, or your project directly from a GitHub repository.',
        button_link: 'https://pages.github.com',
      },
      {
        title: 'GitHub Marketplace',
        description:
          'Start with thousands of actions and applications from our community to help you build, improve, and accelerate your automated workflows.',
        button_link: 'https://github.com/marketplace?type=actions',
      },
      {
        title: 'Webhooks',
        description:
          'Dozens of events, and a webhooks API, help you integrate with and automate work for your repository, organization, or application.',
        button_link:
          'https://docs.github.com/get-started/customizing-your-github-workflow/exploring-integrations/about-webhooks',
      },
      {
        title: 'Hosted runners',
        description:
          'Move automation to the cloud with on-demand Linux, macOS, and Windows environments for your workflow runs, hosted by GitHub.',
        button_link: 'https://docs.github.com/actions/using-github-hosted-runners/about-github-hosted-runners',
      },
      {
        title: 'Self-hosted runners',
        description:
          'More environments and fuller control with labels, groups, and policies to manage runs on your own machines. Plus, the runner application is open source.',
        button_link: 'https://docs.github.com/actions/hosting-your-own-runners',
      },
      {
        title: 'Secrets management',
        description:
          'Share, update, and automatically sync secrets across multiple repositories to increase security and reduce workflow failures.',
        button_link: 'https://docs.github.com/actions/security-guides/encrypted-secrets',
      },
      {
        title: 'Environments',
        description:
          'Meet security and compliance requirements for software delivery with secrets and protection rules.',
        button_link:
          'https://docs.github.com/actions/deployment/targeting-different-environments/using-environments-for-deployment',
      },
      {
        title: 'Deployments',
        description:
          'View which version of your code is running in an environment, including when and why, plus logs for review.',
        button_link:
          'https://docs.github.com/github/administering-a-repository/viewing-deployment-activity-for-your-repository',
      },
      {
        title: 'Workflow visualization',
        description:
          'Map workflows, track their progression in real time, understand complex workflows, and communicate status with the rest of the team.',
        button_link: 'https://docs.github.com/actions/managing-workflow-runs/using-the-visualization-graph',
      },
      {
        title: 'Workflow templates',
        description:
          'Standardize and scale best practices and processes with preconfigured workflow templates shared across your organization.',
        button_link:
          'https://docs.github.com/actions/getting-started-with-github-actions/starting-with-preconfigured-workflow-templates',
      },
      {
        title: 'Policies',
        description:
          'Manage Actions usage and permissions by repository and organizations, with additional policies for fork pull requests.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/admin/policies/enforcing-policies-for-your-enterprise/enforcing-policies-for-github-actions-in-your-enterprise',
      },
    ],
  },
  {
    id: 'security',
    items: [
      {
        title: 'Private repositories',
        description:
          "Host code that you don't want to share with the world in private GitHub repositories only accessible to you and people you share them with.",
        button_link: 'https://docs.github.com/repositories/creating-and-managing-repositories/about-repositories',
      },
      {
        title: '2FA',
        description:
          'Add an extra layer of security with two-factor authentication (2FA) when logging into GitHub. Require 2FA and choose from TOTP apps, security keys, and more.',
        button_link:
          'https://docs.github.com/authentication/securing-your-account-with-two-factor-authentication-2fa/configuring-two-factor-authentication-recovery-methods',
      },
      {
        title: 'Required reviews',
        description:
          'Ensure that pull requests have a specific number of approving reviews before collaborators can make changes to a protected branch.',
        button_link:
          'https://docs.github.com/pull-requests/collaborating-with-pull-requests/reviewing-changes-in-pull-requests/about-pull-request-reviews#required-reviews',
      },
      {
        title: 'Required status checks',
        description:
          'Create required status checks to add an extra layer of error prevention on branches using status API to enforce checks.',
        button_link:
          'https://docs.github.com/repositories/configuring-branches-and-merges-in-your-repository/defining-the-mergeability-of-pull-requests/about-protected-branches',
      },
      {
        title: 'Code scanning',
        description:
          'Find vulnerabilities in custom code using static analysis. Prevent new vulnerabilities from being introduced by scanning every pull request.',
        button_link:
          'https://docs.github.com/code-security/code-scanning/automatically-scanning-your-code-for-vulnerabilities-and-errors/about-code-scanning',
      },
      {
        title: 'Secret scanning',
        description:
          'Find secrets hard-coded in your public and private repositories. Revoke them to keep access to the services you use secure.',
        button_link: 'https://docs.github.com/code-security/secret-scanning/about-secret-scanning',
      },
      {
        title: 'Private vulnerability reporting',
        description:
          'Enable your public repository to privately receive vulnerability reports from the community and collaborate on a solution.',
        button_link:
          'https://docs.github.com/code-security/security-advisories/guidance-on-reporting-and-writing/privately-reporting-a-security-vulnerability',
      },
      {
        title: 'Dependency graph',
        description:
          'See the packages your project depends on, the repositories that depend on them, and any vulnerabilities detected in their dependencies.',
        button_link:
          'https://docs.github.com/code-security/supply-chain-security/understanding-your-software-supply-chain/about-the-dependency-graph',
      },
      {
        title: 'Dependabot alerts',
        description:
          'Get notified when there are new vulnerabilities affecting your repositories. GitHub detects and alerts users to vulnerable dependencies in public and private repositories.',
        button_link:
          'https://docs.github.com/code-security/dependabot/dependabot-alerts/about-dependabot-alerts#github-dependabot-alerts-for-vulnerable-dependencies',
      },
      {
        title: 'Dependabot security and version updates',
        description:
          'Keep your supply chain secure and up-to-date by automatically opening pull requests that update vulnerable or out-of-date dependencies.',
        button_link:
          'https://docs.github.com/code-security/dependabot/dependabot-security-updates/configuring-dependabot-security-updates',
      },
      {
        title: 'Dependency review',
        description:
          'Understand the security impact of newly introduced dependencies during pull requests, before they get merged.',
        button_link:
          'https://docs.github.com/pull-requests/collaborating-with-pull-requests/reviewing-changes-in-pull-requests/reviewing-dependency-changes-in-a-pull-request',
      },
      {
        title: 'GitHub Security Advisories',
        description:
          'Privately report, discuss, fix, and publish information about security vulnerabilities found in open source repositories.',
        button_link:
          'https://docs.github.com/code-security/security-advisories/repository-security-advisories/about-repository-security-advisories',
      },
      {
        title: 'GitHub Advisory Database',
        description:
          'Browse or search for the vulnerabilities that GitHub knows about. The database contains all curated CVEs and security advisories on the GitHub dependency graph.',
        button_link:
          'https://docs.github.com/code-security/security-advisories/global-security-advisories/browsing-security-advisories-in-the-github-advisory-database#about-the-github-advisory-database',
      },
      {
        title: 'GPG commit signing verification',
        description:
          'Use GPG, S/MIME, or SSH to sign tags and commits. These are marked as verified on GitHub so other people know the changes come from a trusted source.',
        button_link:
          'https://docs.github.com/authentication/managing-commit-signature-verification/about-commit-signature-verification',
      },
      {
        title: 'Security audit log',
        description:
          'Quickly review the actions performed by members of your organization. Your audit log includes details like who performed an action and when.',
        button_link:
          'https://docs.github.com/enterprise-server@2.22/admin/overview/system-overview#audit-and-access-logging',
      },
      {
        title: 'Repository rules',
        description:
          "Elevate your organization's security with source code protections that scale. Rule insights make it easy to review how and why code changed in your repositories.",
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/repositories/configuring-branches-and-merges-in-your-repository/managing-rulesets/about-rulesets',
      },
      {
        label: <Card.Label color="blue">Enterprise add-on</Card.Label>,
        title: 'SAML',
        description:
          'Control and secure access to organization resources like repositories, issues, and pull requests with SAML. And allow users to authenticate with their existing GitHub usernames.',
        button_link:
          'https://docs.github.com/github-ae@latest/admin/identity-and-access-management/using-saml-for-enterprise-iam/about-saml-for-enterprise-iam',
      },
      {
        label: <Card.Label color="blue">Enterprise add-on</Card.Label>,
        title: 'LDAP',
        description:
          'Centralize repository management. LDAP is one of the most common protocols used to integrate third-party software with large company user directories.',
        button_link:
          'https://docs.github.com/enterprise-server@latest/admin/identity-and-access-management/using-ldap-for-enterprise-iam/using-ldap',
      },
      {
        label: <Card.Label color="blue">Enterprise add-on</Card.Label>,
        title: 'IP allow list',
        description:
          'Limit access to enterprise assets to an allowed set of source IPs. The allow list will block access via the web, API, and Git for any IP addresses not included.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/admin/configuration/configuring-your-enterprise/restricting-network-traffic-to-your-enterprise-with-an-ip-allow-list',
      },
      {
        label: <Card.Label color="blue">Enterprise add-on</Card.Label>,
        title: 'GitHub Connect',
        description:
          'Share features and workflows between your GitHub Enterprise Server instance and GitHub Enterprise Cloud.',
        button_link:
          'https://docs.github.com/enterprise-server@latest/admin/configuration/configuring-github-connect/about-github-connect',
      },
      {
        label: <Card.Label color="blue">Enterprise add-on</Card.Label>,
        title: 'Audit log API',
        description:
          'Keep copies of audit log data to secure your intellectual property and maintain compliance for your organization.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/admin/monitoring-activity-in-your-enterprise/reviewing-audit-logs-for-your-enterprise/using-the-audit-log-api-for-your-enterprise',
      },
      {
        label: <Card.Label color="blue">Enterprise add-on</Card.Label>,
        title: 'Repository rules',
        description:
          "Elevate your organization's security with source code protections that scale. Rule insights make it easy to review how and why code changed in your repositories.",
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/repositories/configuring-branches-and-merges-in-your-repository/managing-rulesets/about-rulesets',
      },
    ],
  },
  {
    id: 'client_apps',
    items: [
      {
        icon: <Card.Icon icon={<DeviceMobileIcon />} color="purple" />,
        title: 'GitHub Mobile',
        description: 'Take your projects, ideas, and code to go with fully native mobile and tablet experiences.',
        button_link: '/mobile',
      },
      {
        icon: <Card.Icon icon={<TerminalIcon />} color="purple" />,
        title: 'GitHub CLI',
        description:
          "Bring GitHub to the command line. Manage issues and pull requests from the terminal, where you're already working with Git and your code.",
        button_link: 'https://cli.github.com',
      },
      {
        icon: <Card.Icon icon={<DeviceDesktopIcon />} color="purple" />,
        title: 'GitHub Desktop',
        description:
          'Simplify your development workflow with a GUI. Visualize, commit, and push changes without ever touching the command line.',
        button_link: 'https://desktop.github.com',
      },
    ],
  },
  {
    id: 'project_management',
    items: [
      {
        title: 'Projects',
        description: 'Create a customized view of your issues and pull requests to plan and track your work.',
        button_link:
          'https://docs.github.com/issues/planning-and-tracking-with-projects/learning-about-projects/about-projects',
      },
      {
        title: 'Labels',
        description:
          'Organize and prioritize your work. Apply labels to issues and pull requests to signify priority, category, or any other information you find useful.',
        button_link: 'https://docs.github.com/issues/using-labels-and-milestones-to-track-work/managing-labels',
      },
      {
        title: 'Milestones',
        description:
          'Track progress on groups of issues or pull requests in a repository, and map groups to overall project goals.',
        button_link: 'https://docs.github.com/issues/using-labels-and-milestones-to-track-work/about-milestones',
      },
      {
        title: 'Issues',
        description:
          'Track bugs, enhancements, and other requests, prioritize work, and communicate with stakeholders as changes are proposed and merged.',
        button_link: 'https://docs.github.com/issues',
      },
      {
        title: 'Charts and insights',
        description:
          "Leverage insights to visualize your projects by creating and sharing charts built from your project's data.",
        button_link:
          'https://docs.github.com/issues/planning-and-tracking-with-projects/viewing-insights-from-your-project',
      },
      {
        title: 'Org dependency insights',
        description:
          'With dependency insights you can view vulnerabilities, licenses, and other important information for the open source projects your organization depends on.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/organizations/collaborating-with-groups-in-organizations/viewing-insights-for-your-organization#viewing-organization-dependency-insights',
      },
      {
        title: 'Repository insights',
        description:
          'Use data about activity and contributions within your repositories, including trends, to make data-driven improvements to your development cycle.',
        button_link:
          'https://docs.github.com/repositories/viewing-activity-and-data-for-your-repository/viewing-a-summary-of-repository-activity',
      },
      {
        title: 'Wikis',
        description:
          'Host documentation for projects in a wiki within your repository. Contributors can easily edit documentation on the web or locally.',
        button_link: 'https://docs.github.com/communities/documenting-your-project-with-wikis/about-wikis',
      },
    ],
  },
  {
    id: 'team_administration',
    items: [
      {
        title: 'Organizations',
        description:
          'Set up groups of user accounts that own repositories. Manage access on a team-by-team, or individual user, basis.',
        button_link: 'https://docs.github.com/organizations',
      },
      {
        title: 'Invitations',
        description:
          'Easily add GitHub members to your repositories using their GitHub username or email address, and require them to confirm access.',
        button_link:
          'https://docs.github.com/organizations/managing-membership-in-your-organization/inviting-users-to-join-your-organization',
      },
      {
        title: 'Teams',
        description:
          "Group your organization members to reflect your company or group's structure with cascading access permissions and mentions.",
        button_link: 'https://docs.github.com/organizations/organizing-members-into-teams/about-teams',
      },
      {
        title: 'Team sync',
        description:
          'Enable team synchronization between your identity provider and your organization on GitHub, including Entra ID and Okta.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/organizations/managing-saml-single-sign-on-for-your-organization/managing-team-synchronization-for-your-organization',
      },
      {
        title: 'Custom roles',
        description:
          "Define users' level of access to your code, data and settings based on their role in your organization.",
        button_link: 'https://docs.github.com/get-started/learning-about-github/access-permissions-on-github',
      },
      {
        title: 'Custom repository roles',
        description:
          'Ensure members have only the permissions they need by creating custom roles with fine-grained permission settings.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/organizations/managing-peoples-access-to-your-organization-with-roles/managing-custom-repository-roles-for-an-organization',
      },
      {
        title: 'Domain verification',
        description:
          "Verify your organization's identity on GitHub and display that verification through a profile badge.",
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/organizations/managing-organization-settings/verifying-or-approving-a-domain-for-your-organization',
      },
      {
        title: 'Verified and approved domains',
        description: 'Make sure emails only go to your company’s email inbox by approving corporate domains.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/organizations/managing-organization-settings/verifying-or-approving-a-domain-for-your-organization',
      },
      {
        title: 'Audit log API',
        description:
          'Quickly review the actions performed by members of your organization. Monitor access, permission changes, user changes, and other events.',
        button_link:
          'https://docs.github.com/organizations/keeping-your-organization-secure/managing-security-settings-for-your-organization/reviewing-the-audit-log-for-your-organization#using-the-audit-log-api',
      },
      {
        title: 'Audit log streaming',
        description:
          'Prevent audit log loss by streaming your enterprise audit log with leading system information and event management tools and cloud storage providers.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/admin/monitoring-activity-in-your-enterprise/reviewing-audit-logs-for-your-enterprise/streaming-the-audit-log-for-your-enterprise',
      },
      {
        title: 'Repository creation restriction',
        description:
          'Restrict repository creation permissions to organization owners only—or allow members to create public and private repositories.',
        button_link:
          'https://docs.github.com/organizations/managing-organization-settings/restricting-repository-creation-in-your-organization',
      },
      {
        title: 'Notification restriction',
        description:
          'Protect information about what your team is working on by restricting email notifications to approved email domains.',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/organizations/keeping-your-organization-secure/managing-security-settings-for-your-organization/restricting-email-notifications-for-your-organization',
      },
      {
        title: 'Enterprise accounts',
        description:
          'Enable collaboration between your organization and GitHub environments with a single point of visibility and management via an enterprise account.',
        button_link: 'https://docs.github.com/enterprise-cloud@latest/admin/overview/about-enterprise-accounts',
      },
      {
        title: 'Compliance reports',
        description:
          'Take care of your security assessment and certification needs by accessing GitHub’s cloud compliance reports, such as our SOC reports and Cloud Security Alliance CAIQ self-assessments (CSA CAIQ).',
        button_link:
          'https://docs.github.com/enterprise-cloud@latest/admin/overview/accessing-compliance-reports-for-your-enterprise',
      },
    ],
  },
  {
    id: 'community',
    items: [
      {
        icon: <Card.Icon icon={<PackageIcon />} color="pink" />,
        title: 'GitHub Marketplace',
        description:
          'Install apps that integrate directly with GitHubʼs API to customize and optimize your workflows – build your own for private use or publication in the GitHub Marketplace.',
        button_link: 'https://github.com/marketplace',
      },
      {
        icon: <Card.Icon icon={<HeartIcon />} color="pink" />,
        title: 'GitHub Sponsors',
        description:
          'Financially support the open source projects your code depends on. Sponsor a contributor, maintainer, or project with one time or recurring contributions.',
        button_link: 'https://github.com/sponsors',
      },
      {
        icon: <Card.Icon icon={<BookIcon />} color="pink" />,
        title: 'The ReadME Project',
        description:
          'The ReadME Project is part of GitHub’s ongoing effort to amplify the voices of the developer community.',
        button_link: 'https://github.com/readme',
      },
      {
        icon: <Card.Icon icon={<ChecklistIcon />} color="pink" />,
        title: 'GitHub skills',
        description:
          'Learn new skills by completing tasks and projects directly within GitHub, guided by our friendly bot.',
        button_link: 'https://skills.github.com',
      },
      {
        icon: <Card.Icon icon={<CodeIcon />} color="pink" />,
        title: 'Electron',
        description:
          'Write cross-platform desktop applications using JavaScript, HTML and CSS with the Electron framework, based on Node.js and Chromium.',
        button_link: 'https://www.electronjs.org/docs/latest',
      },
      {
        icon: <Card.Icon icon={<MortarBoardIcon />} color="pink" />,
        title: 'Education',
        description:
          'More than a platform. GitHub Education is a commitment to bringing tech and open source collaboration to students and educators across the globe.',
        button_link: 'https://github.com/education',
      },
    ],
  },
]

interface Item {
  label?: JSX.Element
  icon?: JSX.Element
  title: string
  description: string
  button_link: string
}

const renderItemsForSection = (sectionId: string) => {
  const section = featuresData.find(sec => sec.id === sectionId)
  if (!section) return null

  return section.items.map((item: Item, index) => (
    <Grid.Column span={{xsmall: 12, medium: 6, large: 4}} key={index}>
      <Card className="lp-Card" href={item.button_link}>
        {item.label && item.label}
        {item.icon && item.icon}
        <Card.Heading size="6">{item.title}</Card.Heading>
        <Card.Description>{item.description}</Card.Description>
      </Card>
    </Grid.Column>
  ))
}

export function FeaturesIndex() {
  return (
    <ThemeProvider colorMode="dark" className="fp-hasFontSmoothing">
      {/* Hero */}

      <section id="hero" className="lp-Section--hero">
        {/* Header + Subnav = 136px */}
        <Spacer size="136px" />
        <Spacer size="48px" size1012="96px" />

        <div className="fp-Section-container">
          <Hero data-hpc align="center" className="fp-Hero">
            <Hero.Heading className="fp-Hero-heading" size="2">
              The tools you need to build what you want.
            </Hero.Heading>
          </Hero>

          <Spacer size="40px" size1012="80px" />

          <Bento className="lp-Bento">
            <Bento.Item visualAsBackground columnSpan={{xsmall: 12, medium: 6}} rowSpan={{xsmall: 4, xlarge: 3}}>
              <Bento.Content verticalAlign="start" padding={{xsmall: 'normal', small: 'spacious'}}>
                <Bento.Heading as="h2" size="5" weight="semibold">
                  Experience AI with Copilot Chat
                </Bento.Heading>
                <Link href="https://github.com/features/copilot">Learn more</Link>
              </Bento.Content>
              <Bento.Visual>
                <Image src="/images/modules/site/features/fp24/hero-bento-1.webp" alt="" width="600" />
              </Bento.Visual>
            </Bento.Item>

            <Bento.Item visualAsBackground columnSpan={{xsmall: 12, medium: 6}} rowSpan={{xsmall: 4, xlarge: 3}}>
              <Bento.Content verticalAlign="start" padding={{xsmall: 'normal', small: 'spacious'}}>
                <Bento.Heading as="h2" size="5" weight="semibold">
                  The latest GitHub previews
                </Bento.Heading>
                <Link href="https://github.com/features/preview">Learn more</Link>
              </Bento.Content>
              <Bento.Visual>
                <Image src="/images/modules/site/features/fp24/hero-bento-2.webp" alt="" width="600" />
              </Bento.Visual>
            </Bento.Item>
          </Bento>

          <Spacer size="48px" size1012="96px" />

          <Box className="fp-AnchorNav">
            <AnchorNav>
              <AnchorNav.Link href="#features-collaboration">Collaborative coding</AnchorNav.Link>
              <AnchorNav.Link href="#features-automation">Automation CI&CD</AnchorNav.Link>
              <AnchorNav.Link href="#features-security">Security</AnchorNav.Link>
              <AnchorNav.Link href="#features-apps">Client apps</AnchorNav.Link>
              <AnchorNav.Link href="#features-project-management">Project management</AnchorNav.Link>
              <AnchorNav.Link href="#features-team-administration">Team Administration</AnchorNav.Link>
              <AnchorNav.Link href="#features-community">Community</AnchorNav.Link>
            </AnchorNav>
          </Box>
        </div>
      </section>

      {/* Collaboration */}

      <section id="features-collaboration">
        <div className="fp-Section-container">
          <div className="lp-Intro">
            <Image
              className="lp-IntroLine"
              src="/images/modules/site/features/fp24/intro-gitline.svg"
              width={8}
              height={124}
              alt=""
            />
            <Image
              className="lp-IntroVisual"
              width={64}
              height={64}
              src="/images/modules/site/features/fp24/intro-collaboration.webp"
              alt=""
            />
          </div>

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Collaborative Coding</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="48px" size1012="96px" />

          <RiverBreakout className="fp-RiverBreakout">
            <RiverBreakout.Visual>
              <Image
                className="fp-RiverBreakoutVisualImage"
                src="/images/modules/site/features/fp24/section-collaboration.webp"
                alt="Screenshot of a code review conversation in GitHub, showing a code change where a line has been edited to include variableDeprecations in addition to versionDeprecations and selectorDeprecations. The change is highlighted, with the old line in red and the new line in green. Below the code, there is a conversation thread with comments from three users, appreciating the catch and expressing satisfaction with the teamwork. The conversation ends with a 'Resolve conversation' button."
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              className="fp-RiverBreakout-content"
              style={{rowGap: '0'}} // Remove gap when 2nd row is unused
              trailingComponent={() => (
                <Timeline>
                  <Timeline.Item>
                    <em>See the changes</em> you care about.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Build community</em> around your code.
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text>
                <em>Contribute to projects quickly</em> with automatic environment setup.
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <Spacer size="64px" size1012="96px" />

          <Grid className="lp-Grid">
            {/* Render items */}
            {renderItemsForSection('collaborative_coding')}
          </Grid>
        </div>
      </section>

      {/* Automation */}

      <section id="features-automation">
        <div className="fp-Section-container">
          <div className="lp-Intro">
            <Image
              className="lp-IntroLine"
              src="/images/modules/site/features/fp24/intro-gitline.svg"
              width={8}
              height={124}
              alt=""
            />
            <Image
              className="lp-IntroVisual"
              width={80}
              height={80}
              src="/images/modules/site/features/fp24/intro-automation.webp"
              alt=""
            />
          </div>

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Automation and CI/CD</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="48px" size1012="96px" />

          <RiverBreakout className="fp-RiverBreakout">
            <RiverBreakout.Visual>
              <Image
                className="fp-RiverBreakoutVisualImage"
                src="/images/modules/site/features/fp24/section-automation.webp"
                alt="Screenshot of a CI/CD pipeline in GitHub, showing the progress of a build-release workflow. The pipeline includes steps for building on Ubuntu, Windows, and macOS, followed by testing. The production deployment is pending for web-app, web-app-eu, and database, each waiting for additional processes or reviews. The background gradient transitions from blue to green, with a 'Review deployments' button at the top right."
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              className="fp-RiverBreakout-content"
              style={{rowGap: '0'}} // Remove gap when 2nd row is unused
              trailingComponent={() => (
                <Timeline>
                  <Timeline.Item>
                    <em>Standardize and scale</em> best practices, security, and compliance across your organization.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Get started quickly with thousands of actions</em> from partners and the community.
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text>
                <em>Automate everything:</em> CI/CD, testing, planning, project management, issue labeling, approvals,
                onboarding, and more.
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <Spacer size="64px" size1012="96px" />

          <Grid className="lp-Grid">
            {/* Render items */}
            {renderItemsForSection('automation')}
          </Grid>

          <Spacer size="56px" size1012="112px" />
        </div>
      </section>

      {/* Security */}

      <section id="features-security" className="fp-Section--isRaised lp-Cards--isRaised">
        <div className="fp-Section-container">
          <div className="lp-Intro">
            <Image
              className="lp-IntroLine"
              src="/images/modules/site/features/fp24/intro-gitline.svg"
              width={8}
              height={124}
              alt=""
            />
            <Image
              className="lp-IntroVisual"
              width={64}
              height={64}
              src="/images/modules/site/features/fp24/intro-security.webp"
              alt=""
            />
          </div>

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Security & Advanced Security</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="48px" size1012="96px" />

          <RiverBreakout className="fp-RiverBreakout">
            <RiverBreakout.Visual>
              <Image
                className="fp-RiverBreakoutVisualImage"
                src="/images/modules/site/features/fp24/section-security.webp"
                alt="Screenshot illustrating GitHub Advanced Security (GHAS) in action. The left side shows a line graph tracking the number of vulnerabilities by severity (Critical, High, Moderate, Low) over time, with data points from January 1 to February 15, 2024. The right side displays a security bot's recommendation to fix a vulnerability in the code. The bot explains that user-provided input is being used in an HTTP response without sanitization, potentially leading to a cross-site scripting (XSS) attack. The AI-suggested fix involves using the escape-html library to sanitize the input, with the old code in red and the new, corrected code in green. The background features a smooth blue gradient."
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              className="fp-RiverBreakout-content"
              style={{rowGap: '0'}} // Remove gap when 2nd row is unused
              trailingComponent={() => (
                <Timeline>
                  <Timeline.Item>
                    <em>Secure your code with automatic reviews,</em> catching vulnerabilities early.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Understand and address any vulnerabilities</em> in your open source dependencies.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Automatically detect and deactivate secrets</em> committed to your repos. With application
                    security tools at their fingertips, developers fix vulnerabilities up to 7x faster than those using
                    third-party tools.
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text>
                <em>Secure code as you write it.</em> Automatically. Additional features available to GitHub Enterprise
                customers.
              </Text>
              <Link href="/enterprise/advanced-security">Explore GitHub Advanced Security</Link>
            </RiverBreakout.Content>
          </RiverBreakout>

          <Spacer size="64px" size1012="96px" />

          <Grid className="lp-Grid">
            {/* Render items */}
            {renderItemsForSection('security')}
          </Grid>

          <Spacer size="64px" size1012="128px" />
        </div>
      </section>

      {/* Client apps */}

      <section id="features-apps">
        <div className="fp-Section-container">
          <div className="lp-Intro">
            <Image
              className="lp-IntroLine"
              src="/images/modules/site/features/fp24/intro-gitline.svg"
              width={8}
              height={124}
              alt=""
            />
            <Image
              className="lp-IntroVisual"
              width={80}
              height={80}
              src="/images/modules/site/features/fp24/intro-apps.webp"
              alt=""
            />
          </div>

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Client apps</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="48px" size1012="96px" />

          <RiverBreakout className="fp-RiverBreakout">
            <RiverBreakout.Visual>
              <Image
                className="fp-RiverBreakoutVisualImage"
                src="/images/modules/site/features/fp24/section-apps.webp"
                alt="Screenshot showcasing GitHub across Desktop, Mobile, and Command Line interfaces. The Desktop interface shows a repository with multiple changed files, highlighting app/npm-shrinkwrap.json and a comparison of code changes. The Command Line interface displays the output of the gh pr status command, showing the status of pull requests, with some passing checks and one failing. The Mobile interface on the right side displays the 'Home' screen with options like Issues, Pull Requests, Discussions, and more."
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              className="fp-RiverBreakout-content"
              style={{rowGap: '0'}} // Remove gap when 2nd row is unused
              trailingComponent={() => (
                <Timeline>
                  <Timeline.Item>
                    <em>Accessible Anywhere.</em> Use GitHub on macOS, Windows, mobile, or tablet with native apps.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Efficient Management.</em> Handle pull requests, issues, and tasks swiftly with GitHub CLI or
                    mobile.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Streamlined Development.</em> Visualize and commit changes easily with GitHub Desktop.
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text>
                <em>Access GitHub anywhere:</em> On Desktop, Mobile, and Command Line.
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <Spacer size="64px" size1012="96px" />

          <Grid className="lp-Grid">
            {/* Render items */}
            {renderItemsForSection('client_apps')}
          </Grid>
        </div>
      </section>

      {/* Project Management */}

      <section id="features-project-management">
        <div className="fp-Section-container">
          <div className="lp-Intro">
            <Image
              className="lp-IntroLine"
              src="/images/modules/site/features/fp24/intro-gitline.svg"
              width={8}
              height={124}
              alt=""
            />
            <Image
              className="lp-IntroVisual"
              width={80}
              height={80}
              src="/images/modules/site/features/fp24/intro-project-management.webp"
              alt=""
            />
          </div>

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Project Management</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="48px" size1012="96px" />

          <RiverBreakout className="fp-RiverBreakout">
            <RiverBreakout.Visual>
              <Image
                className="fp-RiverBreakoutVisualImage"
                src="/images/modules/site/features/fp24/section-project-management.webp"
                alt="Screenshot of a GitHub Projects board titled 'Product Roadmap,' displaying three columns: Backlog, In Progress, and Triage. Each column contains cards representing issues or tasks, with labels and tags indicating status, priority, iteration, and design requirements. The board features a gradient background transitioning from blue to green."
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              className="fp-RiverBreakout-content"
              style={{rowGap: '0'}} // Remove gap when 2nd row is unused
              trailingComponent={() => (
                <Timeline>
                  <Timeline.Item>
                    <em>Coordinate initiatives big and small</em> with project tables, boards, and tasklists.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Engineered for software teams.</em>
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Track what you deliver down to the commit.</em>
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text>
                <em>Keep feature requests, bugs, and more</em> organized with GitHub issues.
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <Spacer size="64px" size1012="96px" />

          <Grid className="lp-Grid">
            {/* Render items */}
            {renderItemsForSection('project_management')}
          </Grid>
        </div>
      </section>

      {/* Team administration */}

      <section id="features-team-administration">
        <div className="fp-Section-container">
          <div className="lp-Intro">
            <Image
              className="lp-IntroLine"
              src="/images/modules/site/features/fp24/intro-gitline.svg"
              width={8}
              height={124}
              alt=""
            />
            <Image
              className="lp-IntroVisual"
              width={80}
              height={80}
              src="/images/modules/site/features/fp24/intro-team-administration.webp"
              alt=""
            />
          </div>

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Team administration</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="48px" size1012="96px" />

          <RiverBreakout className="fp-RiverBreakout">
            <RiverBreakout.Visual>
              <Image
                className="fp-RiverBreakoutVisualImage"
                src="/images/modules/site/features/fp24/section-team-administration.webp"
                alt="Screenshot of a GitHub Team Administration board showing the 'Who has access' section for a private repository. The page displays access levels, including 'Base Role' where all 23 GitHub IAM members have read access, 'Direct Access' for 14 members, and 'Organization Access' for 12 members. The 'Manage access' section below lists individual users with options to create a team, add people, or add a team. Each user entry includes their role, such as 'Write' or 'Read,' along with options to modify their access. The background features a gradient from pink to purple."
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              className="fp-RiverBreakout-content"
              style={{rowGap: '0'}} // Remove gap when 2nd row is unused
              trailingComponent={() => (
                <Timeline>
                  <Timeline.Item>
                    <em>Update permissions, add new users as you grow,</em> and give everyone the exact permissions they
                    need.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Sync with Okta and Entra ID.</em>
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text>
                <em>Simplify access and permissions management</em> across your projects and teams.
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <Spacer size="64px" size1012="96px" />

          <Grid className="lp-Grid">
            {/* Render items */}
            {renderItemsForSection('team_administration')}
          </Grid>

          <Spacer size="56px" size1012="112px" />
        </div>
      </section>

      {/* Community */}

      <section id="features-community" className="fp-Section--isRaised lp-Cards--isRaised">
        <div className="fp-Section-container">
          <div className="lp-Intro">
            <Image
              className="lp-IntroLine"
              src="/images/modules/site/features/fp24/intro-gitline.svg"
              width={8}
              height={124}
              alt=""
            />
            <Image
              className="lp-IntroVisual"
              width={80}
              height={80}
              src="/images/modules/site/features/fp24/intro-community.webp"
              alt=""
            />
          </div>

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Community</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="48px" size1012="96px" />

          <Image
            className="lp-SectionImage"
            src="/images/modules/site/features/fp24/section-community.webp"
            alt="Screenshot of GitHub Sponsors cards, displaying various open-source projects and individuals available for sponsorship. Each card includes the project or individual's name, an avatar or logo, and a 'Sponsor' button with a heart icon. The background features a gradient transitioning from dark purple to bright orange."
          />

          <Spacer size="64px" size1012="96px" />

          <Grid className="lp-Grid">
            {/* Render items */}
            {renderItemsForSection('community')}
          </Grid>

          <Spacer size="56px" size1012="112px" />
        </div>
      </section>

      {/* CTA */}

      <section id="cta">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <CTABanner className="lp-CTABanner" align="center" hasShadow={false}>
            <CTABanner.Heading size="2">Ready to get started?</CTABanner.Heading>
            <CTABanner.Description className="lp-CTABannerDescription">
              Explore all the plans to find the solution that fits your needs.
            </CTABanner.Description>
            <CTABanner.ButtonGroup buttonsAs="a">
              <Button href="/pricing">View pricing plans</Button>
            </CTABanner.ButtonGroup>
          </CTABanner>

          <Spacer size="64px" size1012="128px" />
        </div>
      </section>
    </ThemeProvider>
  )
}

try{ FeaturesIndex.displayName ||= 'FeaturesIndex' } catch {}