import {
  ThemeProvider,
  Hero,
  SectionIntro,
  Text,
  River,
  RiverBreakout,
  Stack,
  Timeline,
  CTABanner,
  Button,
  Image,
  Pillar,
  Grid,
  Heading,
} from '@primer/react-brand'
import {Spacer} from './components/Spacer'

const extURLs = {
  cta_enable: 'https://docs.github.com/discussions/quickstart',
}

export function DiscussionsIndex() {
  return (
    <ThemeProvider colorMode="light" className="fp-hasFontSmoothing">
      {/* Header + Subnav = 136px */}
      <Spacer size="136px" />
      <Spacer size="48px" size1012="96px" />

      {/* Hero */}

      <section id="hero" className="lp-Section--hero">
        <div className="fp-Section-container">
          <Hero data-hpc className="fp-Hero" align="center" aria-label="">
            <Hero.Label color="purple">GitHub Discussions</Hero.Label>
            <Hero.Heading className="fp-Hero-heading" size="2">
              The home for
              <br />
              developer communities
            </Hero.Heading>
            <Hero.Description className="fp-Hero-description" size="300">
              Ask questions, share ideas, and build connections with each other—all right next to your code. GitHub
              Discussions enables healthy and productive software collaboration.
            </Hero.Description>
            <Hero.PrimaryAction href={extURLs.cta_enable}>Enable Discussions</Hero.PrimaryAction>
          </Hero>

          {/* Hero.Image moved to a background image */}
          <span className="sr-only">
            Screenshot of a GitHub Discussions page for the ’octoinvaders’ project, showing categorized discussion
            threads with tags like ’answered’ and ’Long term.’ The interface features playful elements like a mona emjoi
            and a rocket icon, highlighting community interaction.
          </span>
        </div>
      </section>

      {/* Features */}

      <section id="features">
        <div className="fp-Section-container">
          <SectionIntro className="fp-SectionIntro" align="center">
            <SectionIntro.Heading size="3">Dedicated space for conversations</SectionIntro.Heading>
            <SectionIntro.Description>
              Decrease the burden of managing active work in issues and pull requests by providing a separate space to
              host ongoing discussions, questions, and ideas.
            </SectionIntro.Description>
          </SectionIntro>

          <Spacer size="64px" size1012="96px" />

          <River className="fp-River" align="end">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/discussions/fp24/features-river-1.webp"
                alt="Screenshot of a GitHub Discussion where JohnCreek asks for game power-up ideas, and tonyjaramillo's reply suggesting 'Hubot' as a sidekick is marked as the accepted answer. The background has a pink-to-purple gradient."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold">
                <strong>Mark what’s most helpful.</strong> Highlight quality responses and make the best answer more
                discoverable for future community members to find.
              </Text>
            </River.Content>
          </River>

          <River className="fp-River" align="end">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/discussions/fp24/features-river-2.webp"
                alt="Screenshot of a GitHub Discussions thread where enstyled suggests a power-up idea involving Hubot revealing a path and protecting Mona. The post has received 5 upvotes and several reactions. Below, pifafu and tobiasahlin add to the discussion, suggesting Hubot could provide different power-ups depending on levels and appreciating the collaboration idea. The background has a gradient from pink to purple."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold">
                <strong>Thread conversations.</strong> Keep context in-tact and conversations on track with threaded
                comments.
              </Text>
            </River.Content>
          </River>

          <River className="fp-River" align="end">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/discussions/fp24/features-river-3.webp"
                alt="Screenshot of a GitHub poll created by JohnCreek titled 'Power-up contest.' The poll asks participants to choose their favorite power-up idea for Mona in a game. The poll is part of a discussion in the 'Feedback' category, and the background features a gradient from pink to purple."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold">
                <strong>Ask your community with polls.</strong> Gauge interest in a feature, vote on a meetup time, or
                learn more about your community with custom polls.
              </Text>
            </River.Content>
          </River>

          <River className="fp-River" align="end">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/discussions/fp24/features-river-4.webp"
                alt="Screenshot of a Slack-like interface named 'OctoSlack' with the #community channel selected. The right panel displays a community feed from GitHub Discussions, where Robotoocat announces a new poll created by johncreek titled 'Power-up contest.' The message includes a snippet of the poll description and a link to the discussion. Robotoocat also notes that ajashams voted in the poll. The interface features a purple-to-pink gradient background."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold">
                <strong>Leverage GraphQL API and webhooks.</strong> Decrease maintainer burden by integrating your
                workflows where your teams already are.
              </Text>
            </River.Content>
          </River>

          <River className="fp-River" align="end">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/discussions/fp24/features-river-5.webp"
                alt="Screenshot of a closed GitHub issue titled 'Gameplay improvements #1192.' CameronFoxly suggests adding power-ups, and Johncreek proposes taking the discussion to the community. The post has likes and reactions. The background has a pink-to-purple gradient."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Text size="400" weight="semibold" style={{color: 'var(--brand-color-text-default'}}>
                <strong>Give your open ended conversations the room</strong> they need outside of issues.Convert
                discussions into issues when you’re ready to scope out work.
              </Text>
            </River.Content>
          </River>

          <Spacer size="64px" size1012="128px" />
        </div>
      </section>

      {/* Discuss */}

      <section id="discuss" className="fp-Section--isRaised">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <RiverBreakout className="fp-RiverBreakout">
            <RiverBreakout.A11yHeading>Customize</RiverBreakout.A11yHeading>
            <RiverBreakout.Visual>
              <Image
                className="fp-RiverBreakoutVisualImage"
                src="/images/modules/site/discussions/fp24/discuss-river-1.webp"
                alt="Screenshot of the GitHub Discussions page for 'octoinvaders,' showing featured discussions like 'Game dev log,' 'Power-up contest,' and 'Fan Art showcase.' Categories include General, Feedback, and Q&A. The interface has a dark theme with a pink-to-purple gradient background."
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              className="fp-RiverBreakout-content"
              style={{rowGap: '0'}} // Remove gap when 2nd row is unused
              trailingComponent={() => (
                <Timeline>
                  <Timeline.Item>
                    <em>Custom categories</em> Create discussion categories that fit your communityʼs needs.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Label and organize</em> Make announcements and the most important discussions more visible for
                    contributors.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Pin discussions</em> Make announcements and the most important discussions more visible for
                    contributors.
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text>
                <em>
                  Personalize for your community and team with any ways to make your space unique for you and your
                  collaborators.
                </em>
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <Spacer size="64px" size1012="128px" />

          <SectionIntro className="fp-SectionIntro">
            <SectionIntro.Heading>Monitor insights</SectionIntro.Heading>
            <SectionIntro.Description>
              Track the health and growth of your community with a dashboard full of actionable data.
            </SectionIntro.Description>
          </SectionIntro>

          <Spacer size="40px" size1012="80px" />

          <Stack
            direction={{narrow: 'vertical', regular: 'horizontal', wide: 'horizontal'}}
            gap="spacious"
            padding="none"
          >
            <div style={{margin: '0 auto'}}>
              <div className="lp-PillarVisual">
                <Image
                  className="lp-PillarVisualImage"
                  src="/images/modules/site/discussions/fp24/statement-1.webp"
                  alt="Contribution activity graph"
                />
              </div>
              <Pillar>
                <Pillar.Heading>Contribution activity</Pillar.Heading>
                <Pillar.Description>
                  Count of total contribution activity to Discussions, Issues, and PRs.
                </Pillar.Description>
              </Pillar>
            </div>
            <div style={{margin: '0 auto'}}>
              <div className="lp-PillarVisual">
                <Image
                  className="lp-PillarVisualImage"
                  src="/images/modules/site/discussions/fp24/statement-2.webp"
                  alt="Discussions page views graph"
                />
              </div>
              <Pillar>
                <Pillar.Heading>Discussions page views</Pillar.Heading>
                <Pillar.Description>
                  Total page views to Discussions segmented by logged in vs anonymous users.
                </Pillar.Description>
              </Pillar>
            </div>
            <div style={{margin: '0 auto'}}>
              <div className="lp-PillarVisual">
                <Image
                  className="lp-PillarVisualImage"
                  src="/images/modules/site/discussions/fp24/statement-3.webp"
                  alt="Discussions daily contributors graph"
                />
              </div>
              <Pillar>
                <Pillar.Heading>Discussions daily contributors</Pillar.Heading>
                <Pillar.Description>
                  Count of unique users who have reacted, upvoted, marked an answer, commented, or posted in the
                  selected period.
                </Pillar.Description>
              </Pillar>
            </div>
          </Stack>

          <Spacer size="64px" size1012="128px" />
        </div>
      </section>

      {/* Mobile */}

      <section id="mobile">
        <div className="fp-Section-container">
          <Spacer size="56px" size1012="112px" />

          <SectionIntro className="fp-SectionIntro" align="center">
            <SectionIntro.Heading size="3">Respond on-the-go</SectionIntro.Heading>
            <SectionIntro.Description>
              Converse, check in, and respond to discussions whenever and wherever is convenient for you.
            </SectionIntro.Description>
          </SectionIntro>

          <Spacer size="32px" size1012="64px" />

          <Image
            src="/images/modules/site/discussions/fp24/mobile-1.webp"
            alt="Screenshot of a mobile interface for GitHub Discussions in the 'octoinvaders' project. Featured discussions include 'Game dev log' and 'March game design update.' The page shows categories like Feedback and Show & tell, with active discussions and reactions."
            width={920}
            style={{display: 'block', margin: '0 auto'}}
          />
        </div>
      </section>

      {/* Community */}

      <section id="community">
        <div className="fp-Section-container">
          <Spacer size="32px" size1012="48px" />

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Get inspired</SectionIntro.Heading>
            <SectionIntro.Description>See how your favorite communities are using discussions</SectionIntro.Description>
          </SectionIntro>

          <Spacer size="40px" size1012="80px" />

          <Grid className="lp-Grid">
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, small: 6, medium: 4, large: 4, xlarge: 3}}>
              <a
                className="lp-CommunityItem"
                href="https://github.com/vercel/next.js/discussions"
                rel="nofollow noreferrer"
              >
                <div className="lp-CommunityItem-visual">
                  <Image
                    className="lp-CommunityItem-logo"
                    src="/images/modules/site/discussions/fp24/community/next-js.svg"
                    alt="Next.js logo"
                  />
                </div>
                <div className="lp-CommunityItem-content">
                  <Heading as="h3" size="6" className="lp-CommunityItem-heading">
                    Next.js
                  </Heading>
                  <Text as="p" className="lp-CommunityItem-description" size="100" variant="muted">
                    10k+ discussions
                  </Text>
                </div>
              </a>
            </Grid.Column>

            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, small: 6, medium: 4, large: 4, xlarge: 3}}>
              <a
                className="lp-CommunityItem"
                href="https://github.com/tailwindlabs/tailwindcss/discussions"
                rel="nofollow noreferrer"
              >
                <div className="lp-CommunityItem-visual">
                  <Image
                    className="lp-CommunityItem-logo"
                    src="/images/modules/site/discussions/fp24/community/tailwindcss.svg"
                    alt="TailwindCSS logo"
                  />
                </div>
                <div className="lp-CommunityItem-content">
                  <Heading as="h3" size="6" className="lp-CommunityItem-heading">
                    TailwindCSS
                  </Heading>
                  <Text as="p" className="lp-CommunityItem-description" size="100" variant="muted">
                    3k+ discussions
                  </Text>
                </div>
              </a>
            </Grid.Column>

            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, small: 6, medium: 4, large: 4, xlarge: 3}}>
              <a
                className="lp-CommunityItem"
                href="https://github.com/Homebrew/discussions/discussions"
                rel="nofollow noreferrer"
              >
                <div className="lp-CommunityItem-visual">
                  <Image
                    className="lp-CommunityItem-logo"
                    src="/images/modules/site/discussions/fp24/community/homebrew.svg"
                    alt="Homebrew logo"
                  />
                </div>
                <div className="lp-CommunityItem-content">
                  <Heading as="h3" size="6" className="lp-CommunityItem-heading">
                    Homebrew
                  </Heading>
                  <Text as="p" className="lp-CommunityItem-description" size="100" variant="muted">
                    4k+ discussions
                  </Text>
                </div>
              </a>
            </Grid.Column>

            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, small: 6, medium: 4, large: 4, xlarge: 3}}>
              <a
                className="lp-CommunityItem"
                href="https://github.com/twbs/bootstrap/discussions"
                rel="nofollow noreferrer"
              >
                <div className="lp-CommunityItem-visual">
                  <Image
                    className="lp-CommunityItem-logo"
                    src="/images/modules/site/discussions/fp24/community/bootstrap.svg"
                    alt="Bootstrap logo"
                  />
                </div>
                <div className="lp-CommunityItem-content">
                  <Heading as="h3" size="6" className="lp-CommunityItem-heading">
                    Bootstrap
                  </Heading>
                  <Text as="p" className="lp-CommunityItem-description" size="100" variant="muted">
                    600+ discussions
                  </Text>
                </div>
              </a>
            </Grid.Column>

            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, small: 6, medium: 4, large: 4, xlarge: 3}}>
              <a
                className="lp-CommunityItem"
                href="https://github.com/laravel/framework/discussions"
                rel="nofollow noreferrer"
              >
                <div className="lp-CommunityItem-visual">
                  <Image
                    className="lp-CommunityItem-logo"
                    src="/images/modules/site/discussions/fp24/community/laravel.svg"
                    alt="Laravel logo"
                  />
                </div>
                <div className="lp-CommunityItem-content">
                  <Heading as="h3" size="6" className="lp-CommunityItem-heading">
                    Laravel
                  </Heading>
                  <Text as="p" className="lp-CommunityItem-description" size="100" variant="muted">
                    1.6k+ discussions
                  </Text>
                </div>
              </a>
            </Grid.Column>

            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, small: 6, medium: 4, large: 4, xlarge: 3}}>
              <a className="lp-CommunityItem" href="https://github.com/d3/d3/discussions" rel="nofollow noreferrer">
                <div className="lp-CommunityItem-visual">
                  <Image
                    className="lp-CommunityItem-logo"
                    src="/images/modules/site/discussions/fp24/community/d3-js.svg"
                    alt="D3.js logo"
                  />
                </div>
                <div className="lp-CommunityItem-content">
                  <Heading as="h3" size="6" className="lp-CommunityItem-heading">
                    D3.js
                  </Heading>
                  <Text as="p" className="lp-CommunityItem-description" size="100" variant="muted">
                    30k+ discussions
                  </Text>
                </div>
              </a>
            </Grid.Column>

            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, small: 6, medium: 4, large: 4, xlarge: 3}}>
              <a
                className="lp-CommunityItem"
                href="https://github.com/symfony/symfony/discussions"
                rel="nofollow noreferrer"
              >
                <div className="lp-CommunityItem-visual">
                  <Image
                    className="lp-CommunityItem-logo"
                    src="/images/modules/site/discussions/fp24/community/symfony.svg"
                    alt="Symfony logo"
                  />
                </div>
                <div className="lp-CommunityItem-content">
                  <Heading as="h3" size="6" className="lp-CommunityItem-heading">
                    Symfony
                  </Heading>
                  <Text as="p" className="lp-CommunityItem-description" size="100" variant="muted">
                    700+ discussions
                  </Text>
                </div>
              </a>
            </Grid.Column>
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, small: 6, medium: 4, large: 4, xlarge: 3}}>
              <a
                className="lp-CommunityItem"
                href="https://github.com/vuejs/core/discussions"
                rel="nofollow noreferrer"
              >
                <div className="lp-CommunityItem-visual">
                  <Image
                    className="lp-CommunityItem-logo"
                    src="/images/modules/site/discussions/fp24/community/vuejs.svg"
                    alt="VueJS logo"
                  />
                </div>
                <div className="lp-CommunityItem-content">
                  <Heading as="h3" size="6" className="lp-CommunityItem-heading">
                    VueJS
                  </Heading>
                  <Text as="p" className="lp-CommunityItem-description" size="100" variant="muted">
                    50+ discussions
                  </Text>
                </div>
              </a>
            </Grid.Column>
          </Grid>
        </div>
      </section>

      {/* CTA */}

      <section id="cta">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <ThemeProvider colorMode="dark">
            <CTABanner className="lp-CTABanner" align="center" hasShadow={false}>
              <CTABanner.Heading size="2">Start the conversation with your community</CTABanner.Heading>
              <CTABanner.ButtonGroup buttonsAs="a">
                <Button href={extURLs.cta_enable}>Enable Discussions</Button>
              </CTABanner.ButtonGroup>
            </CTABanner>
          </ThemeProvider>
        </div>
      </section>

      <Spacer size="64px" size1012="128px" />
    </ThemeProvider>
  )
}

try{ DiscussionsIndex.displayName ||= 'DiscussionsIndex' } catch {}