import {
  ThemeProvider,
  Hero,
  River,
  Image,
  Text,
  Heading,
  RiverBreakout,
  Timeline,
  SectionIntro,
  Pillar,
  Grid,
  Testimonial,
  FAQ,
  CTABanner,
  Button,
  ComparisonTable,
  Link,
} from '@primer/react-brand'
import {Spacer} from './components/Spacer'
import {BugIcon, DatabaseIcon, DeviceMobileIcon, ZapIcon} from '@primer/octicons-react'

const extURLs = {
  get_started_cta: 'https://github.com/codespaces',
  get_started_doc: 'https://docs.github.com/codespaces/overview',
}

export function CodespacesIndex() {
  return (
    <ThemeProvider colorMode="dark" className="fp-hasFontSmoothing">
      {/* Header + Subnav = 136px */}
      <Spacer size="136px" />
      <Spacer size="48px" size1012="96px" />

      {/* Hero */}

      <section id="hero" className="lp-SectionHero">
        <div className="fp-Section-container">
          <Hero data-hpc className="fp-Hero" align="center">
            <Hero.Label color="green">GitHub Codespaces</Hero.Label>
            <Hero.Heading className="fp-Hero-heading" size="2">
              Secure development made simple
            </Hero.Heading>
            <Hero.Description className="fp-Hero-description" size="300">
              GitHub Codespaces gets you up and coding faster with fully configured, secure cloud development
              environments native to GitHub.
            </Hero.Description>
            <Hero.PrimaryAction href={extURLs.get_started_cta}>Get started for free</Hero.PrimaryAction>
            <Hero.SecondaryAction hasArrow={false} href="#pricing">
              Explore pricing
            </Hero.SecondaryAction>
            <Hero.Image
              className="lp-HeroImage"
              src="/images/modules/site/codespaces/fp24/hero.webp"
              alt="Screenshot of GitHub Codespaces, showing a split-screen interface. On the left side, there's an HTML file (index.html) being edited, with code defining a webpage structure, including an image, a navigation button, and a section for a headline and paragraph text. Below the code editor, there's a terminal window displaying tasks being started and completed, such as watch-extension: vscode-api-tests. On the right side of the screen, the rendered webpage is displayed, featuring a bold headline 'Mona Sans. A Variable Font' with a subheading describing the typeface. Below the text is an illustration of the GitHub mascot, Mona the Octocat, standing on a floating platform."
            />
          </Hero>
        </div>
      </section>

      {/* Features */}

      <section id="features">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <River className="fp-River">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/codespaces/fp24/features-river-1.webp"
                alt="Graphic featuring three key security features of GitHub Codespaces. Each feature is listed in a black rectangle with a green checkmark icon to the left. The features include 'Isolated Environments,' 'Access Control,' and 'Cost Control.' The background transitions from green at the top to teal at the bottom"
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Heading as="h2" size="4">
                Secure by design
              </Heading>
              <Text>
                Created with security in mind, Codespaces provides a secure development environment through its built-in
                capabilities and native integration with the GitHub platform.
              </Text>
            </River.Content>
          </River>

          <River className="fp-River">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/codespaces/fp24/features-river-2.webp"
                alt="Screenshot showing a snippet of a JSON configuration file used in GitHub Codespaces. The file defines settings for a development environment, including specifying an image with the key 'image' set to ghcr.io/github/github/dev_image:latest. It also includes configurations like 'forwardPorts' for automatically forwarding specific ports (such as 80, 2222, 3003, and others), a terminal setting with the key 'terminal.integrated.shell.linux' set to /bin/bash, and a 'postCreateCommand' to run npm install after setup. The background features a gradient from green to teal."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Heading as="h2" size="4">
                Collaborate where you code
              </Heading>
              <Text>Codespaces provides a shared development environment and removes the need for complex setups.</Text>
            </River.Content>
          </River>

          <Spacer size="64px" size1012="128px" />

          <RiverBreakout className="fp-RiverBreakout">
            <RiverBreakout.A11yHeading>Customize your Codespaces</RiverBreakout.A11yHeading>
            <RiverBreakout.Visual>
              <Image
                className="fp-RiverBreakoutVisualImage"
                src="/images/modules/site/codespaces/fp24/features-river-breakout.webp"
                alt="Screenshot of a GitHub Codespaces environment titled 'codespaces-demo.' The interface shows a file explorer on the left side with various files and folders, including .devcontainer, build.css, and setup-devcontainer.sh. The main editor displays the content of the build.css file, with CSS code specifying styles for elements like .main and .container > header. On the right side, a panel allows the user to select a machine configuration, with options for 8-core, 16-core, and 32-core setups, indicating different levels of RAM and storage. The background features a gradient from teal to green."
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              className="fp-RiverBreakout-content"
              trailingComponent={() => (
                <Timeline>
                  <Timeline.Item>
                    <em>Start coding instantly from anywhere in the world.</em> Switching projects? Grab a new machine
                    from the cloud that’s preconfigured for that project. Your settings travel with you.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Tabs or spaces? Monokai or Solarized? Prettier or Beautify? It’s up to you.</em> Control every
                    nerdy detail only you care about with your own dotfiles repository.
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text>
                <em>Your space, your way.</em> Codespaces is a home away from home for your code that feels just like
                your usual machine.
                <Image
                  className="lp-FeaturesLogos"
                  src="/images/modules/site/codespaces/fp24/features-logos.webp"
                  width={496}
                  alt="Logos for popular development tools and extensions, including ES Lint, IntelliCode, Prettier, Live Server, Copilot, Docker, GitLens, and Debugger for Java."
                />
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <Spacer size="64px" size1012="128px" />

          <River className="fp-River">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/codespaces/fp24/features-river-3.webp"
                alt="Screenshot of a GitHub Codespaces environment showing a browser window with the text 'hello, world!1!!' displayed. Below the browser, there is a 'Ports' panel listing several active ports, including 'web (3000),' 'hmr (55306),' 'mysql (3306),' and 'api (3001).' A context menu is open with options like 'Open in Browser,' 'Set Port Label,' 'Copy Local Address,' and 'Make Public,' with the cursor hovering over the 'Make Public' option. The background features a gradient from blue to green."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Heading as="h2" size="4">
                Browser preview and port forwarding
              </Heading>
              <Text>
                Preview your changes and get feedback from teammates by sharing ports within the scope allowed by
                policy.
              </Text>
            </River.Content>
          </River>

          <River className="fp-River">
            <River.Visual className="fp-River-visual">
              <Image
                src="/images/modules/site/codespaces/fp24/features-river-4.webp"
                alt="Screenshot showing a list of three active GitHub Codespaces environments.The first environment is titled 'mn-webgl-sandbox' under the branch 'webgl-changes,' and shows zero down arrows and six up arrows indicating changes. The second environment is titled 'ui refactoring' under the branch 'layout-refactor,' showing four down arrows and two up arrows. The third environment is titled 'psychic space doodle' under the branch 'node-extensions,' with one down arrow and five up arrows. Each environment is marked as 'Active' with a green dot and a small avatar representing the user associated with the environment. The background features a gradient from teal to green."
              />
            </River.Visual>
            <River.Content className="fp-River-content">
              <Heading as="h2" size="4">
                Onboard faster
              </Heading>
              <Text>
                Quickly spin up a codespace with only an IDE or browser and a GitHub account. With a few configuration
                files, you can give your developers an instant, fully configured, and secure development environment so
                they can start coding immediately.
              </Text>
            </River.Content>
          </River>
        </div>
      </section>

      {/* Context */}

      <section id="context">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">What you can do with Codespaces</SectionIntro.Heading>
            <SectionIntro.Link href="https://github.blog/2021-08-11-githubs-engineering-team-moved-codespaces/">
              Learn how GitHub builds with Codespaces
            </SectionIntro.Link>
          </SectionIntro>

          <Spacer size="40px" size1012="80px" />

          <Grid className="lp-Grid">
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 6}}>
              <Pillar>
                <Pillar.Icon icon={<DeviceMobileIcon />} color="green" />
                <Pillar.Heading size="5">Code from any device</Pillar.Heading>
                <Pillar.Description>
                  Want to code on an iPad? Go for it. Spin up Codespaces from any device with internet access. Don’t
                  worry if your device is powerful enough—Codespaces lives in the cloud.
                </Pillar.Description>
              </Pillar>
            </Grid.Column>
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 6}}>
              <Pillar>
                <Pillar.Icon icon={<ZapIcon />} color="green" />
                <Pillar.Heading size="5">Onboard at the speed of thought</Pillar.Heading>
                <Pillar.Description>
                  No more building your dev environment while you onboard. Codespaces launches instantly from any
                  repository on GitHub with pre-configured, secure environments.
                </Pillar.Description>
              </Pillar>
            </Grid.Column>
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 6}}>
              <Pillar>
                <Pillar.Icon icon={<DatabaseIcon />} color="green" />
                <Pillar.Heading size="5">Streamline contractor onboarding</Pillar.Heading>
                <Pillar.Description>
                  Codespaces gives you control over how your consultants access your resources, while providing them
                  with instant onboarding and a fluid developer experience.
                </Pillar.Description>
              </Pillar>
            </Grid.Column>
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 6}}>
              <Pillar>
                <Pillar.Icon icon={<BugIcon />} color="green" />
                <Pillar.Heading size="5">Fix bugs right from a pull request</Pillar.Heading>
                <Pillar.Description>
                  Got a pull request detailing a bug or security issue? Open Codespaces right from the pull request
                  without waiting for your dev environment to load.
                </Pillar.Description>
              </Pillar>
            </Grid.Column>
          </Grid>
        </div>
      </section>

      {/* Testimonials */}

      <section id="testimonials">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">What developers are saying</SectionIntro.Heading>
          </SectionIntro>

          <Spacer size="40px" size1012="80px" />

          <Grid className="lp-Grid">
            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 6}}>
              <Testimonial quoteMarkColor="green">
                <Testimonial.Quote>
                  What used to be a 15-step process is just one step: open Codespaces and you’re off and running.
                </Testimonial.Quote>
                <Testimonial.Name position="Developer Lead, Synergy">Clint Chester</Testimonial.Name>
                <Testimonial.Avatar
                  src="/images/modules/site/codespaces/fp24/testimonials-avatar-clint.png"
                  alt="Circular avatar from Clint Chester's GitHub profile"
                />
              </Testimonial>
            </Grid.Column>

            <Grid.Column className="lp-GridColumn" span={{xsmall: 12, medium: 6}}>
              <Testimonial quoteMarkColor="green">
                <Testimonial.Quote>
                  Codespaces… lets developers skip the tedious, error-prone stuff that normally stands between them and
                  getting started on real work.
                </Testimonial.Quote>
                <Testimonial.Name position="Cloud Capability Lead, KPMG, UK">Keith Annette</Testimonial.Name>
                <Testimonial.Avatar
                  src="/images/modules/site/codespaces/fp24/testimonials-avatar-keith.png"
                  alt="Circular avatar from Keith Annette's GitHub profile"
                />
              </Testimonial>
            </Grid.Column>
          </Grid>
        </div>
      </section>

      {/* Pricing */}

      <section id="pricing" className="lp-SectionPricing">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <SectionIntro className="fp-SectionIntro" align="center" fullWidth>
            <SectionIntro.Heading size="3">Environments on demand</SectionIntro.Heading>
            <SectionIntro.Description>
              Individuals can use Codespaces for free each month for 60 hours, with pay-as-you-go pricing after. Teams
              or Enterprises pay for Codespaces. A maximum monthly cap can also be set for extra pricing control.
            </SectionIntro.Description>
            <SectionIntro.Link href={extURLs.get_started_doc}>Get started</SectionIntro.Link>
          </SectionIntro>

          <Spacer size="40px" size1012="80px" />

          <div className="lp-ComparisonTable">
            <Heading as="h3" size="6">
              Environments on demand
            </Heading>
            <Text as="p" variant="muted" className="lp-ComparisonTable-description">
              Individuals can use Codespaces for free each month for 60 hours, with pay-as-you-go pricing after. Teams
              or Enterprises pay for Codespaces. A maximum monthly cap can also be set for extra pricing control.
            </Text>

            <Spacer size="24px" size1012="32px" />

            <ComparisonTable variant="minimal">
              <ComparisonTable.Row>
                <ComparisonTable.Cell>Cores</ComparisonTable.Cell>
                <ComparisonTable.Cell>Price</ComparisonTable.Cell>
                <ComparisonTable.Cell>per month free for individuals</ComparisonTable.Cell>
              </ComparisonTable.Row>
              <ComparisonTable.Row>
                <ComparisonTable.Cell>2 cores</ComparisonTable.Cell>
                <ComparisonTable.Cell>$0.18 USD/hour</ComparisonTable.Cell>
                <ComparisonTable.Cell>60 hours</ComparisonTable.Cell>
              </ComparisonTable.Row>
              <ComparisonTable.Row>
                <ComparisonTable.Cell>4 cores</ComparisonTable.Cell>
                <ComparisonTable.Cell>$0.36 USD/hour</ComparisonTable.Cell>
                <ComparisonTable.Cell>30 hours</ComparisonTable.Cell>
              </ComparisonTable.Row>
              <ComparisonTable.Row>
                <ComparisonTable.Cell>8 cores</ComparisonTable.Cell>
                <ComparisonTable.Cell>$0.72 USD/hour</ComparisonTable.Cell>
                <ComparisonTable.Cell>15 hours</ComparisonTable.Cell>
              </ComparisonTable.Row>
              <ComparisonTable.Row>
                <ComparisonTable.Cell>16+ cores</ComparisonTable.Cell>
                <ComparisonTable.Cell>
                  <Link href="https://docs.github.com/billing/managing-billing-for-github-codespaces/about-billing-for-github-codespaces">
                    See pricing docs
                  </Link>
                </ComparisonTable.Cell>
                <ComparisonTable.Cell>N/A</ComparisonTable.Cell>
              </ComparisonTable.Row>
            </ComparisonTable>

            <Spacer size="32px" size1012="64px" />

            <Heading as="h3" size="6">
              Storage
            </Heading>
            <Text as="p" variant="muted" className="lp-ComparisonTable-description">
              As an individual, you can try GitHub Codespaces for free up to 60 hours and 15GB storage per month. Decide
              how many cores you need and go. Your free hours and storage automatically reset each month.
            </Text>

            <Spacer size="24px" size1012="32px" />

            <ComparisonTable variant="minimal">
              <ComparisonTable.Row>
                <ComparisonTable.Cell>Plan</ComparisonTable.Cell>
                <ComparisonTable.Cell>Rate</ComparisonTable.Cell>
                <ComparisonTable.Cell />
              </ComparisonTable.Row>
              <ComparisonTable.Row>
                <ComparisonTable.Cell>For individuals</ComparisonTable.Cell>
                <ComparisonTable.Cell>15 GB /month free</ComparisonTable.Cell>
                <ComparisonTable.Cell />
              </ComparisonTable.Row>
              <ComparisonTable.Row>
                <ComparisonTable.Cell>Regular rate</ComparisonTable.Cell>
                <ComparisonTable.Cell>$0.07 USD /GB per month </ComparisonTable.Cell>
                <ComparisonTable.Cell />
              </ComparisonTable.Row>
            </ComparisonTable>
          </div>
        </div>
      </section>

      {/* CTA */}

      <section id="cta">
        <div className="fp-Section-container">
          <Spacer size="40px" size1012="80px" />

          <CTABanner className="lp-CTABanner" align="center" hasShadow={false}>
            <CTABanner.Heading size="2">Start coding in seconds with Codespaces</CTABanner.Heading>
            <CTABanner.Description className="lp-CTABannerDescription">
              Go to any repository and open your own Codespaces environment instantly.
            </CTABanner.Description>
            <CTABanner.ButtonGroup buttonsAs="a">
              <Button href={extURLs.get_started_cta}>Get started for free</Button>
            </CTABanner.ButtonGroup>
          </CTABanner>
        </div>
      </section>

      {/* FAQs */}

      <section id="faq">
        <div className="fp-Section-container">
          <Spacer size="64px" size1012="128px" />

          <FAQ className="lp-FAQ">
            <FAQ.Heading>Frequently asked questions</FAQ.Heading>
            <FAQ.Item>
              <FAQ.Question>How does Codespaces work?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  A codespace is a development environment that’s hosted in the cloud. Customize your project for GitHub
                  Codespaces by{' '}
                  <a href="https://docs.github.com/codespaces/customizing-your-codespace/configuring-codespaces-for-your-project">
                    configuring dev container files
                  </a>{' '}
                  to your repository (often known as configuration-as-code), which creates a repeatable codespace
                  configuration for all users of your project.
                </p>
                <p>
                  GitHub Codespaces run on a various VM-based compute options hosted by GitHub.com, which you can
                  configure from 2 core machines up to 32 core machines. Connect to your codespaces from the browser or
                  locally using an IDE like Visual Studio Code or IntelliJ.
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>How do I use Codespaces?</FAQ.Question>
              <FAQ.Answer>
                <p>There are a number of entry points to spin up a Codespaces environment, including:</p>
                <ul>
                  <li>
                    A <a href="https://docs.github.com/codespaces/getting-started/quickstart">template</a>.
                  </li>
                  <li>
                    <a href="https://docs.github.com/codespaces/developing-in-codespaces/creating-a-codespace#creating-a-codespace">
                      Your repository
                    </a>{' '}
                    for new feature work
                  </li>
                  <li>
                    An{' '}
                    <a href="https://docs.github.com/codespaces/developing-in-codespaces/using-github-codespaces-for-pull-requests#opening-a-pull-request-in-codespaces">
                      open pull request
                    </a>{' '}
                    to explore work-in-progress
                  </li>
                  <li>A commit in the repository’s history to investigate a bug at a specific point in time</li>
                  <li>
                    <a href="https://docs.github.com/codespaces/developing-in-codespaces/using-github-codespaces-in-visual-studio-code#creating-a-codespace-in-vs-code">
                      Visual Studio Code
                    </a>
                  </li>
                  <li>
                    In beta, can you also use your{' '}
                    <a href="https://docs.github.com/codespaces/developing-in-a-codespace/using-github-codespaces-in-your-jetbrains-ide">
                      JetBrains IDE
                    </a>{' '}
                    or{' '}
                    <a href="https://docs.github.com/codespaces/developing-in-a-codespace/getting-started-with-github-codespaces-for-machine-learning">
                      JupyterLab
                    </a>
                  </li>
                </ul>
                <p>
                  Learn more about how to use Codespaces in our{' '}
                  <a href="https://docs.github.com/codespaces/overview">documentation</a>.
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>Is Codespaces available for individual developers?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  Codespaces is available for developers in every organization, and under the control of the
                  organization who pays for the user’s codespace. All personal (individual) GitHub.com accounts include
                  a quota of free usage each month, which organizations can enable (see the next question) for their
                  private and internal repositories. GitHub will provide users in the free plan 120 core hours or 60
                  hours of run time on a 2 core codespace, plus 15 GB of storage each month. See how it’s balanced on
                  the <a href="https://github.com/settings/billing">billing page</a>.
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>Is Codespaces available for teams and companies?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  Codespaces is available for teams and companies, but needs to be enabled first in an organization’s
                  settings. Teams and companies can select which repositories and users have access to Codespaces for
                  added security and permissioning control. Learn how to{' '}
                  <a href="https://docs.github.com/codespaces/managing-codespaces-for-your-organization/enabling-github-codespaces-for-your-organization">
                    enable Codespaces in an organization in our docs
                  </a>
                  .
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>How much does Codespaces cost?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  Codespaces is free for individual use up to 60 hours a month and comes with simple, pay-as-you-go
                  pricing after that. It’s also available for organizations with pay-as-you-go pricing and has pricing
                  controls so any company or team can determine how much they want to spend a month. Learn more about
                  Codespaces pricing for organizations{' '}
                  <a href="https://docs.github.com/billing/managing-billing-for-github-codespaces/about-billing-for-github-codespaces">
                    here
                  </a>
                  .
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>Can I self-host Codespaces?</FAQ.Question>
              <FAQ.Answer>
                <p>Codespaces cannot be self-hosted.</p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>How do I access Codespaces with LinkedIn Learning?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  You can use Codespaces directly through LinkedIn Learning. LinkedIn Learning offers 50+ courses across
                  six of the most popular coding languages, as well as data science and machine learning. These courses
                  are integrated with Codespaces, so you can get hands-on practice anytime, from any machine via
                  LinkedIn. These courses will be unlocked on LinkedIn Learning for free through Feb. 2023. Learn more
                  about LinkedIn Learning and GitHub Codespaces{' '}
                  <a href="https://learning.linkedin.com/product/hands-on-practice">here</a>.
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>How do I enable Codespaces on GitHub?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  Codespaces is on by default for developers with a GitHub free account. If you belong to an
                  organization, there may be a policy that prevents cloning—but if you can clone a repository, you will
                  be able to start using Codespaces. Organizations will also need to pay for, enable, and manage their
                  Codespaces instances.
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>Is Codespaces available for students?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  Codespaces is available for free to students as part of the GitHub Student Developer Pack. Learn more
                  about how to sign up and start using Codespaces and other GitHub products{' '}
                  <a href="https://education.github.com/pack">here</a>.
                </p>
              </FAQ.Answer>
            </FAQ.Item>
            <FAQ.Item>
              <FAQ.Question>Is Codespaces available for open source maintainers?</FAQ.Question>
              <FAQ.Answer>
                <p>
                  Codespaces provides both maintainers and contributors with{' '}
                  <a href="https://docs.github.com/billing/managing-billing-for-github-codespaces/about-billing-for-github-codespaces#monthly-included-storage-and-core-hours-for-personal-accounts">
                    generous free monthly usage
                  </a>
                  .
                </p>
              </FAQ.Answer>
            </FAQ.Item>
          </FAQ>
        </div>
      </section>

      <Spacer size="64px" size1012="128px" />
    </ThemeProvider>
  )
}

try{ CodespacesIndex.displayName ||= 'CodespacesIndex' } catch {}